import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import deTranslations from "./locales/de/translation.json";
import enTranslations from "./locales/en/translation.json";

i18n
  .use(initReactI18next) // Bindet i18n an React
  .init({
    debug: true,
    lng: "de", // Standard-Sprache
    fallbackLng: "de", // Fallback-Sprache
    resources: {
      de: { translation: deTranslations },
      en: { translation: enTranslations },
    }, // Optional, wenn du lokale Übersetzungen direkt angibst
    interpolation: {
      escapeValue: false, // React kümmert sich um das Escaping
    },
    react: {
      useSuspense: true, // WICHTIG
    },
  });

export default i18n;
