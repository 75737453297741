exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beratung-js": () => import("./../../../src/pages/beratung.js" /* webpackChunkName: "component---src-pages-beratung-js" */),
  "component---src-pages-glossar-js": () => import("./../../../src/pages/glossar.js" /* webpackChunkName: "component---src-pages-glossar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projekte-js": () => import("./../../../src/pages/projekte.js" /* webpackChunkName: "component---src-pages-projekte-js" */),
  "component---src-pages-termin-buchen-js": () => import("./../../../src/pages/termin-buchen.js" /* webpackChunkName: "component---src-pages-termin-buchen-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */),
  "component---src-templates-glossar-term-js": () => import("./../../../src/templates/glossar-term.js" /* webpackChunkName: "component---src-templates-glossar-term-js" */),
  "component---src-templates-impressum-js": () => import("./../../../src/templates/impressum.js" /* webpackChunkName: "component---src-templates-impressum-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-teammitglied-js": () => import("./../../../src/templates/teammitglied.js" /* webpackChunkName: "component---src-templates-teammitglied-js" */)
}

